import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

const Menu = () => {
  return (
    <div className="hamburger">
      <div className="button">
        <FontAwesomeIcon icon={faBars} size="1x" />
      </div>
      <nav className="menu">
        <ul>
          <li><Link to='/' >Home</Link></li>
          <li><Link to='/ice-cream-cakes-pies' >Ice Cream Cakes and Pies</Link></li>
          <li><Link to='/worlds-longest-ice-cream-sundae' >World's  Longest  Ice Cream Sundae</Link></li>
          <li><Link to='/contact-us' >Contact Us</Link></li>

        </ul>
      </nav>
    </div>
  )
}

export default Menu