import React from "react"
import { Link } from "gatsby"
import Menu from './Menu'

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  let header = (
    <div className="top_header">

      <div className="container">
        <div className="top_like_menu">
          <div className="social_buttons">
            <a href="https://www.facebook.com/Boice-Bros-Dairy-111447001167" target="_blank"><img src="/images/fb_icon.png" alt="Boice in Facebook"/></a>
            <a href="https://www.instagram.com/boicesmilkhouse/" target="_blank"><img src="/images/ig_icon.png" alt="Boice in Facebook"/></a>
          </div>
          <div className="contact-us">
            <div><Link to="/contact-us">Contact Us</Link></div>
            <div><a href="tel:8453402018">845-340-2018</a></div>
          </div>
          <Menu/>
        </div>
      </div>

      <div className="logo-header">
        <div className="container">
          <a href="/"><h1 className="color_cream">Farm Fresh Ice Cream</h1></a>
        </div>
      </div>

    </div>
  )
    

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>

      <header className="global-header">{header}</header>

      <main>{children}</main>

      <footer>
        <div className="container">
          <div className="copy_text">
            Boice Bros. Ice Cream <br/>
            62 O'Neil Street, Kingston, NY 12401<br/>
            <a href="tel:8453402018">845-340-2018</a>
          </div>
          <div className="social_buttons">
            <a href="https://www.facebook.com/Boice-Bros-Dairy-111447001167" target="_blank"><img src="/images/fb_icon.png" alt="Boice in Facebook"/></a>
            <a href="https://www.instagram.com/boicesmilkhouse/" target="_blank"><img src="/images/ig_icon.png" alt="Boice in Facebook"/></a>
          </div>
        </div>

        <div className="developed"> 
          Website developed by <a href="https://raleighgreeninc.com/" target="_blank">Raleigh Green Inc.</a>
        </div>
        
      </footer>

    </div>
  )
}

export default Layout
